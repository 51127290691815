'use strict';

/**
 * data-ajax-trigger: Items that should trigger the ajax load. The value of "data-ajax-trigger" should the be url where the data is
 * data-ajax-source: Located on the page that matches the url provided by data-ajax-trigger. Value is empty, but all content within that element is loaded into the data-ajax-destination
 * data-ajax-destination: the location of where on the page the ajax content is loaded. A loader/spinner and error messages will also be displayed here when applicable.
 */

;(function ($) {
  $(document).ready(function () {
    var $ajaxContentItems = $('[data-ajax-trigger]');
    var $ajaxDestination = $('[data-ajax-destination]');

    if ($ajaxContentItems.length > 0 && $ajaxDestination.length === 0) console.error('Ajax content items found, but there is no ajax destination');

    if ($ajaxContentItems.length > 0 && $ajaxDestination.length > 0) {
      $ajaxContentItems.map(function (index) {
        var $ajaxItem = $($ajaxContentItems[index]);

        $ajaxItem.on('click', function (e) {
          if ($ajaxItem.attr('data-ajax-prevent-default')) e.preventDefault();

          // Place loader
          $ajaxDestination.html("<div class='loader-container'><div class='loader-roller'><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>s");

          $.ajax({
            url: $ajaxItem.attr('data-ajax-trigger'),
            context: document.body
          }).done(function (data) {
            var content = $(data).find('[data-ajax-source]');

            // remove container classes if they exist
            content.removeClass('container');
            content.find('.container').removeClass('container');

            // load content into destination
            $ajaxDestination.html(content);
          }).fail(function (error) {
            console.error(error);
            $ajaxDestination.html('<div class="notification is-danger">Sorry, but the requested content could not be found.</div>');
          });
        });
      });
    }
  }); // END document ready
})(jQuery);