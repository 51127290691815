'use strict';

/**
 * data-timeline-content-container: Container node for all data-timeline-content items. It's height will be set to the largest item height
 * data-timline-triggers-container: Trigger nodes are contained within this node. The active data-timeline-trigger will be aligned to the left of this container
 * data-timeline-next: Clicking this moves the timeline to the next item.
 * data-timeline-prev: Clicking this moves the timeline to the previous item.
 * data-timeline-content: The node that contains an individual timeline content item. It should be in the same order in the list of data-timeline-content items as it's data-timeline-trigger counterpart. i.e. If 1980 was the 3rd data-timeline-trigger, it's conterpart content (data-timeline-content) should also be the 3rd data-timeline-content in the list of data-timeline-content items.
 * data-timeline-trigger: Clicking this activates that specific trigger and it's data-timeline-content counterpart
 */
;(function ($) {
  $(document).ready(function () {
    var $contentContainer = $('[data-timeline-content-container]');
    var $triggersContainer = $('[data-timline-triggers-container]');
    var $next = $('[data-timeline-next]');
    var $prev = $('[data-timeline-prev]');
    var $contents = $('[data-timeline-content]');
    var $triggers = $('[data-timeline-trigger]');
    var activeIndex = 0;
    var activeClass = 'is-active';
    var triggerWidth = 352;
    var animationDuration = 300;

    function setContentContainerHeight() {
      var height = $($contents[activeIndex]).outerHeight(true);

      $contentContainer.css({
        maxHeight: height,
        overflow: 'hidden',
        display: 'flex',
        alignItems: 'center'
      }).animate({
        height: height
      });
    }

    function setActiveIndex(newActiveIndex) {
      activeIndex = newActiveIndex;

      setContentContainerHeight();

      $triggersContainer.animate({
        left: '-' + activeIndex * triggerWidth
      }, animationDuration);

      $contents.fadeOut(animationDuration);

      setTimeout(function () {
        $($contents[activeIndex]).fadeIn(animationDuration);
        // wait for fadeOut animation to finish
      }, animationDuration);

      $triggers.removeClass(activeClass);
      $($triggers[activeIndex]).addClass('is-active');

      if ($next) {
        if (activeIndex + 1 === $triggers.length) {
          $next.attr('disabled', true);
        } else {
          $next.attr('disabled', false);
        }
      }

      if ($prev) {
        if (activeIndex === 0) {
          $prev.attr('disabled', true);
        } else {
          $prev.attr('disabled', false);
        }
      }
    }

    if ($triggersContainer.length > 0 && $contentContainer.length > 0 && $contents.length > 0 && $triggers.length > 0) {
      if ($contents.length !== $triggers.length) console.error('Timeline: There is not an equal amount of `data-timeline-content` nodes and `data-timeline-trigger` nodes. This is most likely going to cause some inconsistencies in your timeline.');

      // setup some defaults
      triggerWidth = $($triggers[0]).outerWidth(true);
      $triggersContainer.css({
        paddingRight: ($triggers.length - 1) * triggerWidth + 30
      });
      setContentContainerHeight();
      $(window).on('resize', function () {
        return setContentContainerHeight();
      });
      // We're going to start with the first item, so disable the prev button
      if ($prev) $prev.attr('disabled', true);

      $triggers.map(function (index) {
        var $trigger = $($triggers[index]);
        var $content = $($contents[index]);

        // set initial states: Show first content, hide the rest.
        if (index === 0) {
          $trigger.addClass(activeClass);
          $content.removeClass('d-none');
        } else {
          $content.hide().removeClass('d-none');
        }

        // show corresponding content when a trigger is clicked
        $trigger.on('click', function (e) {
          e.preventDefault();
          setActiveIndex(index);
        });
      });

      if ($prev) {
        $prev.on('click', function (e) {
          e.preventDefault();
          var isDisabled = !!$prev.attr('disabled');
          if (!isDisabled) setActiveIndex(activeIndex - 1);
        });
      }

      if ($next) {
        $next.on('click', function (e) {
          e.preventDefault();
          var isDisabled = !!$next.attr('disabled');
          if (!isDisabled) setActiveIndex(activeIndex + 1);
        });
      }
    }
  }); // END document ready
})(jQuery);