"use strict";

;(function ($) {
  $(document).ready(function () {
    // const navBarBurger = (() => {
    // // Check for click events on the navbar burger icon
    // 	$(".navbar-burger").click(function() {
    // 		// Toggle the "is-active" class on both the "navbar-burger" and the "navbar-menu"
    // 		$(".navbar-burger").toggleClass("is-active");
    // 		$(".navbar-menu").toggleClass("is-active");
    // 	});
    // })();
    // const personTrigger = (() => {
    // 	$(".person-trigger").click(function() {
    // 		alert('Your book is overdue.');
    // 		event.preventDefault();
    // 		// Toggle the "is-active" class on the sliding-drawer
    // 		$(".sliding-drawer").toggleClass("is-active");
    // 	});
    // })();
    // const propertyTrigger = (() => {
    // 	$(".property-trigger").click(function() {
    // 		event.preventDefault();
    // 		// Toggle the "is-active" class on the sliding-drawer
    // 		$(".sliding-drawer").toggleClass("is-active");
    // 	});
    // })();
    // const drawerClose = (() => {
    // 	$(".sliding-drawer-close").click(function() {
    // 		event.preventDefault();
    // 		// Toggle the "is-active" class on the sliding-drawer
    // 		$(".sliding-drawer").toggleClass("is-active");
    // 	});
    // })();
    // // reading progressbar
    // var winHeight = $(window).height(),
    // 	docHeight = $(document).height(),
    // 	progressBar = $('progress'),
    // 	max, value;
    // /* Set the max scrollable area */
    // max = docHeight - winHeight;
    // progressBar.attr('max', max);
    // $(document).on('scroll', function(){
    // 	value = $(window).scrollTop();
    // 	progressBar.attr('value', value);
    // });
  }); // END document ready
})(jQuery);