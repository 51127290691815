'use strict';

/**
 * data-tabs-trigger: dom node that when clicked, triggers it's data-tabs-content counterpart to be shown, and all other data-tabs-content items to be hidden. It's value should equal the value of it's data-tabs-content counterpart
 * data-tabs-content: The container for the content of a tab item. It's value should equal the value of it's data-tabs-trigger counterpart.
 */
;(function ($) {
  $(document).ready(function () {
    var $triggers = $('[data-tabs-trigger]');
    var $contents = $('[data-tabs-content]');

    if ($triggers.length > 0 && $contents.length === 0) console.error('Tabs: It looks like you have tab triggers on the page, but no tab content. Please be sure to add the `data-tabs-content` attribute to the corresponding tab dom node.');

    if ($triggers.length === 0 && $contents.length > 0) console.error('Tabs: It looks like you have tab content on the page, but no tab triggers. Please be sure to add the `data-tabs-trigger` attribute to the corresponding tab dom node.');

    function hideAllExcept(indexToShow) {
      $contents.map(function (index) {
        if (index !== indexToShow) {
          $($contents[index]).slideUp();
        } else {
          $($contents[index]).slideDown();
        }
      });
    }

    if ($triggers.length > 0 && $contents.length > 0) {
      // hide all but first item by default
      hideAllExcept(0);

      $triggers.map(function (index) {
        var $trigger = $($triggers[index]);

        $trigger.on('click', function (e) {
          e.preventDefault();
          hideAllExcept(index);
        });
      });
    }
  }); // END document ready
})(jQuery);